.App {
  text-align: center;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/rositas-web.appspot.com/o/Landing%20Page%20Desktop%20Prototype%20Digital%20Marketing.%20Blue%20Illustrative%20Design%20Site%20Template.png?alt=media&token=d63b69c8-a143-4721-9a8d-b6af092f830c");
  background-repeat: repeat;
  background-size: contain;
  padding: 50px 20px;
}
.logosFooter {
  width: 100px;
}

@media (max-width: 450px) {
  .imagen {
    width: 100%;
  }
  .logosFooter {
    width: 30px;
  }
  .imagenAgregador{
    height:30px
  }
 

  .enlace {
   font-size: 14px!important;
  }
}

@media (max-width: 325px) {
  .App-header {
    padding: 50px 5px;
  }
  .logosFooter {
    width: 100%;
  }
  .enlace {
    font-size: 14px!important;
   }
}

@media (max-width: 576px) {
  .logosFooter {
    width: 50px;
  }
}

@media (max-width: 375px) {
  .logosFooter {
    width: 40px;
  }
  .enlace {
    font-size: 14px!important;
   }
}

.enlace {
  padding: 10px 10px;
  text-decoration: none !important;
  color: white;
  background-color: #db988d;
  border-radius: 15px;
  font-size:16px;
  text-transform:none;
  font-weight: bold;
}

.enlace:hover {
  text-decoration: none !important;
  background-color: white;
  color: #db988d;
  border-radius: 15px;
  border: 2px solid #db988d;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
