@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.fondoglovo {
    background-color: #ffc244 !important;
    background-repeat: repeat;
    background-size: cover;
    min-height: 100vh;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: 0px;
    margin-left: 0px;
}

.fondocontent {
    padding-top: 60px;
}

.imgglovo {
    height: 100px !important;
}

.logoglovo {
    height: 25vh;
    padding-top: 25px;
    margin: 0 !important;
    width: 100%;
}

.bolaglovo {
    background: #fff;
    border-radius: 23em;
    height: 100px;
    width: 100px;
    box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
    text-decoration: none;
    overflow: hidden;
    margin-bottom: 40px;
    transition: 0.3s;
    color: #000 !important;
}

.letras {
    line-height: 18px;
    font-size: 21px;
    margin-bottom: 20px;
    width: 100px;
    font-family: "Poppins";
    margin-top: 24px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cajaglovo {
    position: relative;
}

.lengua {
    height: 107px;
    width: 110px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/lgfm-logo.appspot.com/o/Lengua.png?alt=media&token=4986df98-bca6-4048-892a-b092abb4cb3f");
    position: absolute;
    background-size: 115px 115px;
    top: -7px;
    background-repeat: no-repeat;
}

@media (max-width: 1440px) {
    .lengua {
        height: 107px;
        width: 110px;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/lgfm-logo.appspot.com/o/Lengua.png?alt=media&token=4986df98-bca6-4048-892a-b092abb4cb3f");
        position: absolute;
        background-size: 115px 115px;
        top: -7px;
        background-repeat: no-repeat;
    }

    .fondoglovo {
        background-color: #ffc244 !important;
        background-repeat: repeat;
        background-size: cover;
        height: auto;
    }
}

@media (max-width: 1050px) {
    .lengua {
        height: 107px;
        width: 110px;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/lgfm-logo.appspot.com/o/Lengua.png?alt=media&token=4986df98-bca6-4048-892a-b092abb4cb3f");
        position: absolute;
        background-size: 115px 115px;
        top: -7px;
        background-repeat: no-repeat;
    }
}

@media (max-width: 770px) {
    .lengua {
        height: 107px;
        width: 110px;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/lgfm-logo.appspot.com/o/Lengua.png?alt=media&token=4986df98-bca6-4048-892a-b092abb4cb3f");
        position: absolute;
        background-size: 115px 115px;
        top: -7px;
        background-repeat: no-repeat;
    }
}

@media (max-width: 550px) {
    .lengua {
    height: 107px;
    width: 110px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/lgfm-logo.appspot.com/o/Lengua.png?alt=media&token=4986df98-bca6-4048-892a-b092abb4cb3f");
    position: absolute;
    background-size: 115px 115px;
    top: -7px;
    background-repeat: no-repeat;
    }
}

@media (max-width: 385px) {
    .lengua {
    height: 107px;
    width: 110px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/lgfm-logo.appspot.com/o/Lengua.png?alt=media&token=4986df98-bca6-4048-892a-b092abb4cb3f");
    position: absolute;
    background-size: 115px 115px;
    top: -7px;
    background-repeat: no-repeat;
    }
}

@media (max-width: 320px) {
    .bolaglovo {
        background: #fff;
        border-radius: 23em;
        height: 82px;
        width: 100px;
        box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
        text-decoration: none;
        overflow: hidden;
        margin-bottom: 40px;
        transition: 0.3s;
    }

    .imgglovo {
        height: 75px !important;
    }

    .letras {
        line-height: 16px;
        font-size: 18px;
        margin-bottom: 20px;
        width: 100px;
        font-family: "Poppins";
        margin-top: 17px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .lengua {
        height: 105px;
        width: 100px;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/lgfm-logo.appspot.com/o/Lengua.png?alt=media&token=4986df98-bca6-4048-892a-b092abb4cb3f");
        position: absolute;
        background-size: 95px 95px;
        top: -5px;
        background-repeat: no-repeat;
    }
}
